import React, { Suspense, useEffect, useState } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import styled, { ThemeProvider } from 'styled-components';
import {useTheme} from './components/UI/useTheme';
import { UserSession } from './core/UserSession';
import { BrandedStyles } from './components/UI/Branding/BrandedStyles';

// Assets
import './scss/App.scss';

const loading = (
  <div className="pt-3 text-center">
    <Spinner type="grow" color="success" />
  </div>
)

// Containers
const AppLayout = React.lazy(() => import('./containers/AppLayout'));

// User Pages
const Login    = React.lazy(() => import('./views/login'));
const ForgotPassword = React.lazy(() => import('./views/forgot-password'));
// Standard Pages
const Page404  = React.lazy(() => import('./views/page404'));
const Page500  = React.lazy(() => import('./views/page500'));

function App(){
  require('dotenv').config();
  let {theme, themeLoaded} = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const [logo, setLogo] = useState(theme["logo-img"]);
  const [cssFile, setCssFile] = useState(theme['css-file']);
  const [themeUpdate, setThemeUpdate] = useState(0);
  let thisHour = new Date().toLocaleString("en-us", {month: 'numeric', year: 'numeric', day: 'numeric', hour: '2-digit'})

  useEffect(() => {
    setSelectedTheme(theme);

    if(UserSession.isValid()) {
      toggleBranding(false);
    }
   }, []);

   useEffect(() => {
    toggleBranding();
   }, [themeUpdate]);

   const toggleBranding = (resetting) => {
      if(resetting)
        return;

      var setTheme = (resp) => {
        //console.log("HERE'S WHAT CAME BACK", resp);
        var thm = typeof resp.Data == "string" ? JSON.parse(resp.Data) : resp.Data;
        var l = thm["logo-img"];
        setLogo(l);
        setSelectedTheme(thm);
        setCssFile(thm['css-file']);
        themeLoaded = true;
        //console.log("Logo", l);
      };

      var getTheme = () => {
        var sess = UserSession.get();

        if(sess.hasLocation()) {
          UserSession.getTheme(setTheme); 
        }
        themeLoaded = false;
      };

      UserSession.validateCurrent(getTheme, null);
      // if(!Utility.isEmpty(stylesheet)) {
      //     theme['logo-img'] = "https://americanhorrorplex.com/wp-content/uploads/2019/05/american_horrorplex-e1559274109297.png";
      //     setSelectedTheme(theme);      
      // }
   };

   const resetTheme = () => {
      setThemeUpdate(themeUpdate+1);
   };

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        {
          themeLoaded && <ThemeProvider theme={selectedTheme}>
            <BrandedStyles />
            { cssFile ? 
              <link href={cssFile + (cssFile.indexOf("?") > -1 ? "&" : "?") + "__rnd=" + thisHour } type="text/css" rel="stylesheet" /> 
              : ""
            }
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
              <Route exact path="/forgot-password" name="Forgot Password" render={props => <ForgotPassword {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="*" name="Home" render={props => <AppLayout resetTheme={resetTheme} logo={logo} {...props}/>} />
            </Switch>
          </ThemeProvider>
      }
      </Suspense>
    </HashRouter>
  );
}

export default App;
