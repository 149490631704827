import axios from 'axios';
// import { Spinner } from 'reactstrap';
import Spinner from '../components/UI/Spinner';
import { toast } from 'react-toastify';
import BackLink, { BackLinkProps } from '../components/UI/BackLink';
import { getNoEHeaders } from '../redux/serverEndpoints';

export const httpRequest = async (url, urlData, queryData = null, token=null) => {    
    const response = await axios({
            method: urlData.method, 
            url: url, 
            data: queryData, 
            timeout: 60000, 
            headers: getNoEHeaders() 
        });

    return response;   
};

export const setSessionItem = (identifier, newValue) => {
	return sessionStorage.setItem(identifier, newValue);
}

export const removeSessionItem = (identifier) => {
	return sessionStorage.removeItem(identifier);
}

export const getSessionItem = (identifier) => {
	return sessionStorage.getItem(identifier);
}

export const clearSession = () => {
	return sessionStorage.clear();
}

export const setLocalStorageItem = (identifier, newValue) => {
	return localStorage.setItem(identifier, newValue);
}

export const getLocalStorageItem = (identifier) => {
	return localStorage.getItem(identifier);
}

export const clearLocalStorage = () => {
    return localStorage.clear();
}

export const removeLocalStorageItem = (identifier) => {
	return localStorage.removeItem(identifier);
}

export const notifySuccess = (strMessage, intDelay) => { 
	const message = (undefined !== strMessage) ? strMessage : "Saved";
	const delay = (undefined !== intDelay) ? intDelay : 1500;
	toast.success(message, {autoClose: delay})
}

export const notifyError = (strMessage, intDelay) => { 
	const message = (undefined !== strMessage) ? strMessage : "Error";
	const delay = (undefined !== intDelay) ? intDelay : 10000;
	toast.error(message, {autoClose: delay})
}

export const notifyWarning = (strMessage, intDelay) => { 
	const message = (undefined !== strMessage) ? strMessage : "Warning";
	const delay = (undefined !== intDelay) ? intDelay : 5000;
	toast.warn(message, {autoClose: delay})
};
export const notifyInformation = (strMessage, intDelay) => { 
	const message = (undefined !== strMessage) ? strMessage : "Information";
	const delay = (undefined !== intDelay) ? intDelay : 5000;
	toast.info(message, {autoClose: delay})
};

export const escapeHtml = ( str ) => {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return str.replace(/[&<>"']/g, function(m) { return map[m]; });
}

export const CurrentDate = function(strDelimeter){
    let delimeter = (strDelimeter == null) ? "-" : strDelimeter;
    let currentTime = new Date()
    let month = currentTime.getMonth() + 1
    month = (month < 10 ? '0'+month : month);
    let day = currentTime.getDate()
    day = (day < 10 ? '0'+day : day);
    let year = currentTime.getFullYear()
    return year + delimeter + month + delimeter + day;
}

export const CurrentTime = function(){
  let date = new Date();
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let seconds = date.getSeconds();
  hours = hours % 12;
  hours = (hours ? hours : 12);
  hours = (hours < 10 ? '0'+hours : hours);
  minutes = (minutes < 10 ? '0'+minutes : minutes);
  seconds = (seconds < 10 ? '0'+seconds : seconds);
  let strTime = hours + ':' + minutes+":"+seconds;
  return strTime;
}

export const FormatDate = (strDate, type = 'dd/mm/YYYY') => {
    let objDate = new Date(strDate);
    let formatted_date = '';
    switch (type.toLowerCase()){
        case 'dd/mm/yyyy':
            formatted_date =  ("0" + (objDate.getUTCMonth() + 1)).slice(-2) + "/" + ("0" + objDate.getUTCDate()).slice(-2) + "/" + objDate.getUTCFullYear();
            break;
        case 'mm/dd/yyyy':
                formatted_date =  ("0" + objDate.getUTCDate()).slice(-2) + "/" + ("0" + (objDate.getUTCMonth() + 1)).slice(-2) + "/" + objDate.getUTCFullYear();
                break;
        case 'yyyy-mm-dd':
            formatted_date = (objDate.getUTCFullYear()) + "-" + ("0" + (objDate.getUTCMonth() + 1)).slice(-2) + "-" + ("0" + objDate.getUTCDate()).slice(-2);
            break;
        default:
            return strDate;
    }
    
    return formatted_date;
}

export const formatPhoneNumber = (phoneNumberString) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

export const loadingSpinnerCustom = (color = "light", size = "sm") => {
    return (
        <span className="text-center mx-2">
            <Spinner size={size} color={color} />
        </span>
    );
};

export const loadingSpinner = loadingSpinnerCustom();

export const errorLog = (error, verbose = false, returnsValue = false) =>{
    let errorMessage = null;
    if (verbose){
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            errorMessage = error.response
            console.log(errorMessage.data);
            console.log(errorMessage.status);
            console.log(errorMessage.headers);
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            errorMessage = error.request;
            console.log(errorMessage);
        } else {
            // Something happened in setting up the request that triggered an Error
            errorMessage = error.message;
            console.log('Error', errorMessage);
        }
        console.log(error.config);
    }else{
        // console.log('[ERROR LOG]', error);
        if (error.response){
            errorMessage = error.response.data
            console.log(errorMessage);
        }else{
            errorMessage = error;
            console.log(errorMessage);
        }
    }
    if (returnsValue) 
        return errorMessage;
}

export const getErrorMsg = (error) => {
    return (error.response) ? error.response.data.details : error;
}

export const getErrorTitle = (error) => {
    return (error.response) ? error.response.data.title : error;
}

export const isEmpty = (str) => {
    //console.log("STRING----------------------------------", str, ", Empty:", str == "", ", Null:", str == null);
    return str == "" || str == null;
};

export const getQueryParam = (name, val) => {
    if(typeof val == "object") {
        //console.log("QP", name, val);
        return getQueryParams(val, true);
    }
    else {
        return name + '=' + (val ?? '');
    }
};

export const getQueryParams = (obj, isChild = false) => {
    let arr = [];
    for(let col in obj) {
        if(obj[col] != null) {
            arr.push(getQueryParam(col, obj[col]));
        }
    }

    if(arr.length == 0)
        return '';
    else
        return (isChild ? '' : '?') + arr.join('&');
};

export const getLocalDate = (date, showTime = true, doOffset = true) => {
    if(isEmpty(date))
        return "N/A";
    
    const d = (typeof date == "object") ? date : new Date(date);
    return getSimpleDate(d, showTime, doOffset);
};

export const getSimpleDate = (date, showTime = true, doOffset = true) => {
    const oneSec = 1000;
    const oneMin = 60 * oneSec;
    const oneHour = 60 * oneMin;
    const oneDay = 24 * oneHour;
    const oneWeek = oneDay * 7;
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const finalDate = doOffset ? new Date(date.getTime() - (offset * 60 * 1000)) : date;
    const diff = now - finalDate;
    const fiveMin = 5 * oneMin;
    const time = doOffset ? 
                (finalDate.getHours() > 12 ? finalDate.getHours() - 12 : finalDate.getHours()) + ":" + 
                (finalDate.getMinutes() > 9 ? finalDate.getMinutes() : "0" + finalDate.getMinutes()) + " " + 
                (finalDate.getHours() > 11 ? "PM" : "AM") :
                (finalDate.getUTCHours() > 12 ? finalDate.getUTCHours() - 12 : finalDate.getUTCHours()) + ":" + 
                (finalDate.getUTCMinutes() > 9 ? finalDate.getUTCMinutes() : "0" + finalDate.getUTCMinutes()) + " " + 
                (finalDate.getUTCHours() > 11 ? "PM" : "AM");
    
    const dayPieces = doOffset ? [
        finalDate.getMonth() + 1,
        finalDate.getDate()
    ] : [
        finalDate.getUTCMonth() + 1,
        finalDate.getUTCDate()
    ];

    const allDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    if(doOffset) {
        if(finalDate.getFullYear() != now.getFullYear())
            dayPieces.push(finalDate.getFullYear());
    }
    else {
        if(finalDate.getUTCFullYear() != now.getFullYear())
            dayPieces.push(finalDate.getUTCFullYear());
    }

    const day = dayPieces.join("/");
    const dow = allDays[doOffset ? finalDate.getDay() : finalDate.getUTCDay()];
    const suffix = showTime ? " at " + time : "";


    if(diff < 0) {      
        return day + suffix;
    }
    else if (diff < oneMin) {
        return "Just Now";
    }
    else if (diff < fiveMin) {
        return "A few minutes ago";
    }
    else if (diff < oneHour) {
        return "Less than an hour ago";
    }
    else if (diff < oneDay * 2) {
        if(diff < oneDay)
            return showTime ? time : "Today";
        return "Yesterday" + suffix;
    }
    else if (diff < oneWeek * 2)
    {
        if(diff < oneDay * 4)
            return dow + suffix;
        return "Last " + dow + suffix;
    }
    else {
        return day + suffix;
    }
};

export const getBackLink = () => {
    return BackLinkProps.show ? (<div className="back-stripe">
        {BackLinkProps.getBackLink()}
    </div>) : "";
};

// export const userToken = JSON.parse(window.atob(localStorage.getItem('token')));