// If your want to extend the functionality of this component 
// please visit https://getbootstrap.com/docs/4.2/components/spinners/

import React from 'react';

const Spinner = (props) => {
    const spinnerType = props.type ? props.type : "border"
    const spinnerSize = props.size ? props.size : "sm"
    const spinnerColor = props.color ? props.color : "primary"
    return (
        <div className={`spinner-${spinnerType} spinner-${spinnerType}-${spinnerSize} text-${spinnerColor}`} role="status">
            <span className="sr-only">Loading...</span>
        </div>
    )
}

export default Spinner