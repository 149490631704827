import * as actions from '../actions/actionTypes';

//  Initial State
const initialState = {
    is_authenticated: false,
    token: '',
    user_guid: '',
    is_profile_complete: false,
    date_expires:'',
    is_onboarded: false,
    first_name: '',
    last_name: '',
    session_id:'',
    email:'',
    user_type: '',
  }

const userReducer = (state = initialState, action) => {
    // console.log('Action in reducer: ' + JSON.stringify(action));
    // console.log('Payload in reducer: ' + JSON.stringify(action.payload));
    switch(action.type){
        case actions.USER_LOGGED_IN:
            return {...state, ...action.payload, is_authenticated: true};
        case actions.USER_VERIFIED:
            return {...state, ...action.payload, is_verified: true };
        case actions.USER_LOGGED_OUT:
            return {...initialState};
        case actions.USER_FULLNAME_UPDATE:
            return {...initialState, fullname: action.payload};
        case actions.USER_PROFILE_COMPLETE:
            return {...initialState, is_profile_complete: true};
        default:
            return state;
    }
}

export default userReducer;