import { createGlobalStyle} from "styled-components";

export const BrandedStyles = createGlobalStyle`
.App {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    color: ${({ theme }) => theme["secondary"]};
    background-color: ${({ theme }) => theme["app-bg-color"]};
    background-image:url(${({ theme }) => theme["bg-img"]});
    background-repeat:no-repeat;
    background-position:0 50%;
  
    &__wrapper {
      display: flex;
      flex: 1 1;
      flex-direction: column;
      min-width: 0;
      min-height: 100vh;
    }
    &__wrapper:not(.App__wrapper-fluid) .App__header-fixed {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
    }
    &__wrapper:not(.App__wrapper-fluid) .c-body {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
    
    &__header.App__header-fixed {
      position: fixed;
      right: 0;
      left: 0;
      z-index: 1029;
    }
    &__header {
      background: ${({ theme }) => theme["header-bg-color"]};
      border-bottom: 2px solid ${({ theme }) => theme["primary"]};
      position: relative;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-shrink: 0;
      min-height: 50px;
  
      &.see-through .toggle-header-icon, &:hover .hover-only {
        display: block;
        visibility:visible;
      }
  
      .hover-only {
        visibility: hidden;
      }
    }
    &__logo{
      width:${({ theme }) => theme["logo_width"]};
      height:${({ theme }) => theme["logo_height"]};
      height: auto;
      width:25vw;
      /*max-height: 15rem;*/
      @media screen and (max-width: 576px) {
        width: 100%;
      }
    }
    &__main {
      flex-basis: auto;
      flex-shrink: 0;
      flex-grow: 1;
      min-width: 0;
      // padding-top: .25rem;
      padding-bottom: .5rem;
      margin-bottom: 4.5rem;
      @media screen and (max-width: 576px) {
          margin-bottom: 6rem;
      }
    }
    &__advertising{
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 2 !important;
      &--banner-dsk{
        @media screen and (max-width: 576px) {
          display: none;
        }
        @media screen and (min-width: 551px) {
          display: block;
          img{
            width: 100%;
            height: auto;
          }
        }
      }
      &--banner-mob{
        @media screen and (max-width: 576px) {
          display: block;
          img{
            width: 100%;
            height: auto;
          }
        }
        @media screen and (min-width: 551px) {
          display: none;
        }
      }
    }
    &__footer{
      background-color: ${({ theme }) => theme["dark"]};
      color: ${({ theme }) => theme["light"]};
      padding: 1rem;
      text-align: center;
      a {
        color: ${({ theme }) => theme["primary-light"]};
        text-decoration: none;
      }
      a:hover{
        color: ${({ theme }) => theme["primary"]};
      }
    }
    &__overlay{
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba($color: #000000, $alpha: 0.5);
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }
  .card.main-card {
    background-color:rgba(0, 0, 0, .75) !important;
    border-color: ${({ theme }) => theme["primary"]} !important;
  }
  .dropdown-menu button:hover{
    background-color: ${({ theme }) => theme["light"]};
  }
  
  .App__header.see-through {
    max-width:3.5rem;
    background-color:${({ theme }) => theme["primary"]};
    max-height: 3rem;
  }
  
  .display-photo-tile {
    img {
      width:100%;
      border:2px solid ${({ theme }) => theme["primary"]};
      margin:1rem;
      z-index:125;
      cursor: pointer;
    }
  
    .select-photo {
      float:right;
      margin-top:-2rem;
      margin-right:.75rem;
      z-index:152;
      position: relative;
    }
  }
  
  a{
    text-decoration: none !important;
    h1, h2, h3, h4, h5, h6{
      color: ${({ theme }) => theme["secondary"]} !important;
    }
  }
  
  // App Components
  .user{
    &__profile-pict-default{
      border: 3px solid #adb5bd;
      margin: 0 auto;
      padding: 3px;
      width: 80px;
      height: 80px;
    }
    &--secondary{
      background-color: ${({ theme }) => theme["secondary"]};
      color: white;
      font-size: 3rem;
    }
    &__profile-pict{
      border: 3px solid #adb5bd;
      margin: 0 auto;
      padding: 3px;
      height: auto;
      width:100%;
    }
  }
  
  .card{
    border-color: #f0f0f0 !important;
    border-radius: 8px !important;
    // box-shadow: rgba(0, 0, 0, 0.04) 0px 1px 8px 0px, rgba(0, 0, 0, 0.032) 0px 3px 3px -2px, rgba(0, 0, 0, 0.038) 0px 3px 4px 0px;
    &-header{
      h1,h2,h3,h4,h5{
        margin-bottom: .1rem;
      }
    }
    &-header{
      background: transparent;
    }
    &-body{
      padding: auto .5rem;
    }
    &-text{
      padding: auto .5rem !important;
    }
    
    a {
      text-decoration: none;
    }
    &--bl{
      border-left: solid 2px ${({ theme }) => theme["primary"]} !important;
    }
  }
  
  .modal-content {
    background-color:#000 !important;
    color:${({ theme }) => theme["primary"]} !important;
    border:2px solid ${({ theme }) => theme["primary"]} !important;
    max-width:96vw;
    margin:auto;
  }
  
  .badge{
    padding-top: 5px !important;
    &__primary{
      background-color: ${({ theme }) => theme["r-primary"]};
    }
    &__secondary{
      background-color: ${({ theme }) => theme["r-secondary"]};
    }
    &__success{
      background-color: ${({ theme }) => theme["r-success"]};
    }
    &__danger{
      background-color: ${({ theme }) => theme["r-danger"]};
    }
    &__warning{
      background-color: ${({ theme }) => theme["r-warning"]};
    }
    &__info{
      background-color: ${({ theme }) => theme["r-info"]};
    }
    &__light{
      background-color: ${({ theme }) => theme["r-light"]};
    }
    &__dark{
      background-color: ${({ theme }) => theme["r-dark"]};
    }
    &--float-right{
      float: right;
    }
  }
  
  .btn{
    border-radius: .5rem !important;
    font-weight: 500 !important;
    padding: 10px 16px !important;
    &-success{
      background-color: ${({ theme }) => theme["primary"]} !important;
      border-color: ${({ theme }) => theme["primary"]} !important;
    }
    &--block{
      display: block;
      width: 100%;
    }
    &--small{
      padding: 3px 16px !important;
    }
    &-blue{
      background-color: ${({ theme }) => theme["r-primary"]} !important;
      border-color: ${({ theme }) => theme["r-primary"]} !important;
    }
    &-yellow{
      background-color: ${({ theme }) => theme["r-warning"]} !important;
      border-color: ${({ theme }) => theme["r-warning"]} !important;
      i {
        color: #000;
      }
    }
  }
  .border-b{
    border-bottom: solid 1px darken(${({ theme }) => theme["r-light"]}, 8);
  }
  .round-bg{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    border-radius: 50%;
  
    &-primary{
      background-color: ${({ theme }) => theme["r-primary"]};
      color: white;
    }
    &-secondary{
      background-color: ${({ theme }) => theme["r-secondary"]};
      color: white;
    }
    &-success{
      background-color: ${({ theme }) => theme["r-success"]};
      color: white;
    }
    &-danger{
      background-color: ${({ theme }) => theme["r-danger"]};
      color: white;
    }
    &-warning{
      background-color: ${({ theme }) => theme["r-warning"]};
      color: white;
    }
    
    &--sm{
      width: ${({ theme }) => theme["round-bg-sm"]} !important;
      height: ${({ theme }) => theme["round-bg-sm"]} !important;
    }
    &--md{
      width: ${({ theme }) => theme["round-bg-md"]} !important;
      height: ${({ theme }) => theme["round-bg-md"]} !important;
    }
  }
  .focus-border{
    border: solid 3px darken(${({ theme }) => theme["light"]}, 5%) !important;
  }
  
  .pseudo-link {
    color:${({ theme }) => theme["r-primary"]};
    cursor: pointer;
    font-weight: bold;
  }
  
  .back-link-row {
    margin-bottom: .25rem;
  
    .back-link-container {
      cursor:pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
    }
  
    .back-link-icon {
      line-height: 2.5rem !important;
    }
  
    .back-link-text {
      padding-left: .25rem;
      color:${({ theme }) => theme["r-primary"]};
      font-weight: 500;
    }
  }
  
  .warning-cell {
    background-color:${({ theme }) => theme["r-lt-yellow"]};
    padding:.5rem;
    font-style: italic;
  }
  
  .back-stripe {
    background-color:${({ theme }) => theme["r-secondary"]};
    width:100%;
    padding:.5rem;
  }
  
  .cart-item {
    color:${({ theme }) => theme["r-light"]};
  }
  
  .photo-status{
    text-align: center;
    margin-top:-1rem;
    color: ${({ theme }) => theme["primary"]};
    &-text{
      text-transform: capitalize;
      font-weight: bold;
      font-size-adjust: .7;
      &.denied{color:${({ theme }) => theme["r-danger"]};}
      &.approved{color:${({ theme }) => theme["r-success"]};}
    }
  }
`;