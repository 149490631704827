import React from 'react';
import './BsIcon.scss';

const BsIcon = (props) => {
    const size = props.size === undefined ? "1" : props.size;
    const color = props.color === undefined ? "" : props.color;
    const padding = props.padding === undefined ? "" : props.padding;
    const float = props.float === undefined ? "" : {"float":`${props.float}`};
    const customClass = props.className === undefined ? "" : props.className ;
    
    return (
        <i {...props} className={`bi-${props.icon} ${color} ${customClass}`}  style={{ "fontSize":`${size}rem`, "padding":`${padding}`, ...float}}></i>
    )
}

export default BsIcon
