import React from 'react'
import { Input, FormGroup, Label } from 'reactstrap';

const InputFloating = ({children, ...props}) => {
    const label = <label htmlFor={props.id}>{props.label}</label>;
    let input = (<></>);
    const options = props.options ?? [];
    const defValue = props.defvalue ?? props.defValue ?? props.defaultValue;
    const childClassName = props.childClasName ?? "";
    let inputClass = "";
    let classes = ["form-control"];
    props.invalid === 'true' && classes.push("is-invalid")
    inputClass = classes.join(" ");

    const getListResponse = (i, l) => {
        return (
            <div className="form-floating mb-2">
                <div xs="12"><Label xs="12">{l}</Label></div>
                <div xs="12">{i}</div>
            </div>);
    };    

    const mapSelectOptions = (options) => {
        return options.map((rdo, idx) => {
                return <option key={idx} value={rdo.val} className={childClassName}>
                    {rdo.key}
                </option>;
            });
    };

    switch(props.type) {
        case "textarea":
            input = <textarea className={inputClass} id={props.id} placeholder={props.placeholder} {...props}></textarea>;
            break;
        case "select":
        case "select_multiple":
            input = <select multiple={props.multiple || props.type==="select_multiple"} className="form-select" {...props}>
                {children}
                {mapSelectOptions(options)}
            </select>;
            break;
        case "radio":
            input = options.map((rdo, idx) => {
                const rProps = {...props};
                if(rProps.defaultValue)
                    delete rProps.defaultValue;
                return <FormGroup check key={idx}>
                    <Label check>
                        <Input type="radio" name={props.id} value={rdo.val} defaultChecked={defValue === rdo.val} {...rProps} />
                        {rdo.key}
                    </Label>
                </FormGroup>;
            });
            return getListResponse(input, label); 
        case "checkbox":
            input = (<FormGroup check>
                        <Label check>
                            <Input name={props.id} defaultChecked={defValue === true || defValue?.toString().toLowerCase() === "yes" || defValue?.toString().toLowerCase() === "true"}  {...props} />
                            &nbsp;{label}
                        </Label>
                    </FormGroup>);
            return input;//getListResponse(input, label);
        case "checkboxlist":
        case "checkbox_list":
            input = options.map((chk, idx) => {
                return <FormGroup check key={idx}>
                    <Label check>
                        <Input type="checkbox" name={props.id} id={props.id} value={chk.val} 
                            defaultValue={defValue} defaultChecked={defValue === chk.val || chk.checked === true} onChange={props.onChange} />
                            &nbsp;{chk.key}
                    </Label>
                </FormGroup>;
            });
            return getListResponse(input, label); 
        /*  
        case "":
            input = "";
            break;*/
        case "email":
        case "text":
        case "tel":
        default:
            input = (<input className={inputClass} {...props}/>);
            break;
    }
    return (
        <div className="form-floating mb-2">
            {input}
            {label}
        </div>);
}

export default InputFloating
