import { Memory } from "../core/Memory";
import { API_URL } from "../shared/GlobalProps";
import { getQueryParams } from "../shared/utility";

const _url = API_URL; 
export const getNoEHeaders = () => { 
    return { 
    'noe-auth': Memory.getString('noe-auth'), 
    'noe-location': Memory.getString('noe-location'), 
    'noe-account': Memory.getString('noe-account'),
    'noe-user': Memory.getString('noe-user') 
    }
};

export const STANDARD_ARCHIVE =  (obj, guid) => { return { url: _url + `/${obj}/archive/${guid}`, data: { method: 'PUT', auth: true, headers: getNoEHeaders() } } };

export const USER = (guid) => { return { url: _url + '/user/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_PUBLIC = (guid) => { return { url: _url + '/user/public/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_BY_SHARE_CODE = (code) => { return { url: _url + '/user/code/' + code, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_AUTH = { url: _url + '/user/authenticate', data: { method: 'POST', auth: false } };
export const USER_LOGOUT = { url: _url + '/user/logout', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const SESSION_IS_VALID = { url: _url + '/user/is-valid-session', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_CREATE = { url: _url + '/user/create', data: { method: 'POST', auth: false } };
export const USER_VERIFY = (guid) => { return { url: _url + '/user/verify/' + guid, data:{ method: 'POST', auth: false } } };
export const USER_RESEND_VERIFY = { url: _url + '/user/resend-verify', data:{ method: 'POST', auth: false } };
export const USER_UPDATE_PASSWORD = {url: _url + '/user/update-password', data:{ method: 'POST', auth: false } };
export const USER_FORGOT_PASSWORD = { url: _url + '/user/forgot-password', data: { method: 'POST', auth: false } };
export const USER_UPDATE = { url: _url + '/user/update', data: {method: 'PUT', auth: true } };
export const USER_INVITE_REGISTER = { url: _url + '/user/invitation/register', data:{ method: 'POST', auth: false } };
export const USER_UPDATE_ONBOARDED = { url: _url + '/user/update-onboarded', data: {method: 'PUT', auth: true } };
export const USER_BY_LOCATION = (location_guid) => { return { url: _url + '/user/by-location/' + location_guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_BY_ACCOUNT = (account_guid) => { return { url: _url + '/user/by-account/' + account_guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_ADD_TO_LOCATION = { url: _url + '/user/add-to-location', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_ADD_TO_ACCOUNT = { url: _url + '/user/add-account-user', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_REMOVE_FROM_LOCATION = { url: _url + '/user/remove-from-location', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_REMOVE_FROM_ACCOUNT = { url: _url + '/user/remove-from-account', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE = { url: _url + '/user-profile', data: {method: 'GET', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_GET = (guid) => { return { url: _url + '/user-profile/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const USER_PROFILE_CREATE = { url: _url + '/user-profile/create', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_UPDATE = { url: _url + '/user-profile/update', data: {method: 'PUT', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_EDUCATION_ALL = { url: _url + '/user-profile/education/all', data: { method: 'GET', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_EDUCATION_CREATE = { url: _url + '/user-profile/education/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_EDUCATION_DELETE = { url: _url + '/user-profile/education/delete', data: { method: 'DELETE', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_JOB_ALL = { url: _url + '/user-profile/job-history/all', data: { method: 'GET', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_JOB_CREATE = { url: _url + '/user-profile/job-history/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_JOB_DELETE = { url: _url + '/user-profile/job-history/delete', data: { method: 'DELETE', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_COMPLETE = { url: _url + '/user/update-profile-complete', data: { method: 'PUT', auth: true, headers: getNoEHeaders() } };
export const USER_PROFILE_UPLOAD_PHOTO = { url: _url + '/user-profile/upload-photo', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;

export const EMPLOYER_LOCATION_CREATE = { url: _url + '/location/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_LOCATION_UPDATE = { url: _url + '/location/update', data: { method: 'PUT', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_LOCATION_USER = (location_guid, user_guid) => { return { url: _url + '/location/get/' + location_guid + '/user/' + user_guid, data: { method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const EMPLOYER_JOB_CREATE = { url: _url + '/job/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_JOB_UPDATE = { url: _url + '/job/update', data: { method: 'PUT', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_JOB_ARCHIVE =  (guid) => { return { url: _url + '/job/archive/' + guid, data: { method: 'PUT', auth: true, headers: getNoEHeaders() } } };

export const EMPLOYER_ACCOUNT_CREATE = { url: _url + '/account/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_ACCOUNT_UPDATE = () => { return { url: _url + '/account/update', data: { method: 'PUT', auth: true, headers: getNoEHeaders() } } };
export const EMPLOYER_ACCOUNT_USER = (account_guid, user_guid) => { return { url: _url + '/account/get/' + account_guid + '/user/' + user_guid, data: { method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const EMPLOYER_ACCOUNT_BY_USER = { url: _url + '/account/by-user', data: { method: 'GET', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_ACCOUNT_LOCATIONS_BY_USER = { url: _url + '/account/all-companies-locations', data: { method: 'GET', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_ACCOUNT_UPLOAD_LOGO = { url: _url + '/account/upload-logo', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const EMPLOYER_ACCOUNT_ALL_CATEGORIES = { url: _url + '/business-category/all', data: { method: 'GET', auth: true, headers: getNoEHeaders() } };
export const EMPLOYER_ACCOUNT_SUB_CATEGORIES = (id) => { return { url: _url + '/business-category/by-parent/' + id, data: { method: 'GET', auth: true, headers: getNoEHeaders() } } };

export const EMPLOYER_LOCATION_UPLOAD_LOGO = { url: _url + '/location/upload-logo', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;

export const LOCATION = (guid) => { return { url: _url + '/location/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const LOCATION_BY_ACCOUNT = (account_guid) => { return { url: _url + '/location/by-account/' + account_guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const LOCATION_BY_QR = (guid) => { return { url: _url + '/location/' + guid + '/qr-code', data: {method: 'GET', auth: false, headers: getNoEHeaders() } } };
export const LOCATION_BY_WEB_CODE = (web_code) => { return { url: _url + '/location/by-web-code/' + web_code, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const LOCATION_GET_STATS = (guid) => { return { url: _url + '/location/get-stats/' + guid, data: {method: 'POST', auth: true, headers: getNoEHeaders() } } };

export const ACCOUNT = (guid) => { return { url: _url + '/account/' + guid, data: {method: 'GET', auth: false, headers: getNoEHeaders() } } };

export const JOB = (guid) => { return { url: _url + '/job/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_BY_LOCATION = (location_guid) => { return { url: _url + '/job/by-location/' + location_guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_COUNT_BY_LOCATION = (location_guid) => { return { url: _url + '/job/count-by-location/' + location_guid, data: {method: 'GET', auth: false, headers: getNoEHeaders() } } };
export const JOB_SHARE = (guid) => { return { url: _url + '/job/' + guid + '/share', data: {method: 'POST', auth: true, headers: getNoEHeaders() } } };
export const JOB_COPY = (guid) => { return { url: _url + '/job/' + guid + '/copy', data: {method: 'POST', auth: true, headers: getNoEHeaders() } } };
export const JOB_QR = (guid) => { return { url: _url + '/job/' + guid + '/qr-code', data: {method: 'POST', auth: false, headers: getNoEHeaders() } } };
export const JOB_USER_SAVE = (guid) => { return { url: _url + '/job/' + guid + '/user-save', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_USER_UNSAVE = (guid) => { return { url: _url + '/job/' + guid + '/user-unsave', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_MY_SAVED = { url: _url + '/job/my-saved', data: {method: 'GET', auth: true, headers: getNoEHeaders() } };
export const JOB_NEARBY = { url: _url + '/job/get-nearby', data: {method: 'GET', auth: true, headers: getNoEHeaders() } };

export const JOB_APPLICATION = (guid) => { return { url: _url + '/application/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_HIRED = (guid) => { return { url: _url + '/application/' + guid + '/hired', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_NOT_HIRED = (guid) => { return { url: _url + '/application/' + guid + '/not-hired', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_ACCEPT = (guid) => { return { url: _url + '/application/' + guid + '/accept', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_REJECT = (guid) => { return { url: _url + '/application/' + guid + '/reject', data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_CREATE = { url: _url + '/application/create', data: { method: 'POST', auth: true, headers: getNoEHeaders() } };
export const JOB_APPLICATION_BY_FILTER = (filter) => { return { url: _url + '/application/by-filter' + getQueryParams(filter), data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const JOB_APPLICATION_MINE = (filter) => { return { url: _url + '/application/mine' + getQueryParams(filter), data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };


export const PHOTO = (guid) => { return { url: _url + '/photo/get/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const PHOTO_CREATE = { url: _url + '/photo/create', data: { method: 'POST', auth: false } };
export const PHOTO_APPROVE = { url: _url + '/photo/approve', data: {method: 'POST', auth: true } };
export const PHOTO_DECLINE = { url: _url + '/photo/decline', data: {method: 'POST', auth: true } };
export const PHOTO_FAVORITE = { url: _url + '/photo/favorite', data: {method: 'POST', auth: true } };
export const PHOTO_UPDATE = (guid) => { return { url: _url + '/photo/update/' + guid, data: {method: 'POST', auth: true } } };
export const PHOTO_BY_FILTER = { url: _url + '/photo/getlist', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const CART = (guid) => { return { url: _url + '/cart/get/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const CART_CREATE = { url: _url + '/cart/create', data: { method: 'POST', auth: false } };
export const CART_PRINT = (guid) => { return { url: _url + '/cart/print/' + guid, data: { method: 'POST', auth: false } } };
export const CART_UPDATE = (guid) => { return { url: _url + '/cart/update/' + guid, data: {method: 'POST', auth: true } } };
export const CART_DOWNLOAD = (guid) => { return { url: _url + '/cart/download/' + guid, data: {method: 'POST', auth: true } } };
export const CART_EMAIL = (guid) => { return { url: _url + '/cart/email/' + guid, data: {method: 'POST', auth: true } } };
export const CART_GET_RECENT = { url: _url + '/cart/get-recent', data: {method: 'POST', auth: true } };

export const SHIFT = (guid) => { return { url: _url + '/shift/get/' + guid, data: {method: 'GET', auth: true, headers: getNoEHeaders() } } };
export const SHIFT_CREATE = { url: _url + '/shift/create', data: { method: 'POST', auth: false } };
export const SHIFT_CLOSE = (guid) => { return { url: _url + '/shift/close/' + guid, data: { method: 'POST', auth: false } } };
export const SHIFT_UPDATE = (guid) => { return { url: _url + '/shift/update/' + guid, data: {method: 'POST', auth: true } } };
export const SHIFT_BY_FILTER = { url: _url + '/shift/getlist', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };
export const SHIFT_GET_STATS = (guid) => { return { url: _url + '/shift/get-stats/' + guid, data: {method: 'POST', auth: true } } };
export const SHIFT_DEDUPE = { url: _url + '/shift/dedupe', data: {method: 'POST', auth: true, headers: getNoEHeaders() } };

export const LOCATION_GET_BY_USER = { url: _url + '/location/get-by-account', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const LOCATION_GET_THEME = { url: _url + '/location/get-theme', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const ACCOUNT_GET_BY_USER = { url: _url + '/user/get-accounts-by-user', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;

export const TEMPLATE_GET_ACTIVE = { url: _url + '/template/get-active-by-account', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const TEMPLATE_GET_ALL = { url: _url + '/template/get-all-by-account', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const TEMPLATE_GET_TOKENS = { url: _url + '/template/get-replacement-tokens', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const TEMPLATE_ACTIVATE = { url: _url + '/template/activate', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const TEMPLATE_DEACTIVATE = { url: _url + '/template/deactivate', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;

export const PRINTER_GET_ACTIVE = { url: _url + '/printer/get-active-by-location', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const PRINTER_GET_ALL = { url: _url + '/printer/get-all-by-location', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const PRINTER_GET_DEFAULT = { url: _url + '/printer/get-default-by-location', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const PRINTER_SET_DEFAULT = { url: _url + '/printer/set-default-by-location', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const PRINTER_ACTIVATE = { url: _url + '/printer/activate', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;
export const PRINTER_DEACTIVATE = { url: _url + '/printer/deactivate', data: { method: 'POST', auth: true, headers: getNoEHeaders() } } ;