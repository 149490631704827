import { useEffect, useState } from 'react';
import { Memory } from '../../core/Memory';
//import _ from 'lodash';

export const useTheme = () => {
    const baseTheme = {
        "primary": "#70bf44", 
        "primary-light": "#cdf284", 
        "primary-dark": "#5c8804", 
        "secondary": "#3c4b64",
        "light": "#f7f7f7",
        "dark": "#333",
        "gray": "#b2b7c1",
        "app-bg-color": "#000",
        "header-bg-color": "#000000F0",
        // Reactstrap Colors
        "r-primary": "#007bff",
        "r-secondary": "#6c757d",
        "r-success": "#28a745",
        "r-danger": "#dc3545",
        "r-warning": "#ffc107",
        "r-info": "#ffc107",
        "r-light": "#f8f9fa",
        "r-dark": "#343a40",
        "r-lt-yellow": "#fae8b1",
        "r-lt-gray": "#f5f5f5",
        "r-md-gray": "#dedede",

        "overlay-img": "https://images.marketpath.com/fd7889fd-dac4-4f8a-87ef-c6ca38ae7aae/image/fb551120-f984-4826-920b-ac37048c5f78/ScaryFastLogoTransparent.png",
        "bg-img": "https://prd-mp-images.azureedge.net/4c15e768-62de-413e-a99b-a9bf2f69393a/image/bc6c54b2-a05e-47f5-a6b7-120a57b50529/background.png",
        "logo-img": "https://images.marketpath.com/fd7889fd-dac4-4f8a-87ef-c6ca38ae7aae/image/fb551120-f984-4826-920b-ac37048c5f78/ScaryFastLogoTransparent.png"
    };
    const [theme, setTheme] = useState(baseTheme);
    const [themeLoaded, setThemeLoaded] = useState(false);

    const setMode = mode => {
        Memory.saveObject('theme', mode)
        setTheme(mode);
    };

  /*const getFonts = () => {
    const allFonts = _.values(_.mapValues(themes.data, 'font'));
    return allFonts;
  }*/

  useEffect(() =>{
    const localTheme = Memory.getString('theme');
    localTheme ? setTheme(localTheme) : setTheme(baseTheme);
    setThemeLoaded(true);
  }, []);

  return { theme, themeLoaded, setMode, /*getFonts */ };
};