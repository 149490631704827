import * as actions from '../actions/actionTypes';

//  Initial State
const initialState = {
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    postal_code: "",
    description: "",
    website: "",
    phone: "",
    company: {},
    location: {}
  }

const userReducer = (state = initialState, action) => {
    // console.log('Action in Employer reducer: ' + JSON.stringify(action));
    // console.log('Payload in Employer reducer: ' + JSON.stringify(action.payload));
    switch(action.type){
        case actions.COMPANY_CREATED:
            return {...state, ...action.payload, company: {...action.payload} };
        case actions.LOCATION_CREATED:
            return {...state, ...action.payload, location: {...action.payload} };
        default:
            return state;
    }
}

export default userReducer;