import { Row, Col, Card, Input, Label } from "reactstrap";
import { User } from "../core/User";
import InputFloating from "../components/UI/InputFloating";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const dayList = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"]

export const displayShifts = (shifts) => {
    const shiftList = shifts.map((shift, index) => {
        const jobRow =  <Row className="job-shift" key={index}>
                            <Col>{/* ({index}) -*/}{shift.getDays()} {shift.getHours()}</Col>
                        </Row>;
        return jobRow;
    });
    return <>
        {shiftList}
    </>;
};

export const displayShiftCheckboxes = (shifts, fields, onUpdate, refreshView, disableUnchecked = false, lockSelections = false, allowEdit = false) => {
    if(fields.days == null)
        fields.days = {};
    if(fields.shifts == null)
        fields.shifts = {};

    const checkHandler = (field) => {
        field.checked = !field.checked;

        if(onUpdate != null && typeof onUpdate == "function")
            onUpdate(fields);
        if(refreshView != null && typeof refreshView == "function")
            refreshView(Math.random());
    };

    const deleteHandler = (shift) => {
        //console.log("Shifts before", shifts, shift);
        if(!window.confirm("Are you sure?"))
            return;

        shifts.splice(shifts.indexOf(shift), 1);
        //console.log("Shifts after", shifts, shift);

        if(onUpdate != null && typeof onUpdate == "function")
            onUpdate(fields);
        if(refreshView != null && typeof refreshView == "function")
            refreshView(Math.random());
    };

    const getDayCell = (id, val, idx) => {       
        let field = fields.days[val.id]; 
        if(field == null)
            fields.days[val.id] = field = { id: id, label: val.label, checked: val.checked, began_checked: val.checked };
        
        const classes = ["day-check"];
        const allowToggle = field.began_checked || allowEdit || !disableUnchecked;
        
        if(field.checked) {
            classes.push("day-selected");
        }
        else if(allowToggle) {
            classes.push("day-ignored");
        }
        else {
            classes.push("day-disabled");
        }
        return (
            <Col className="avail-cell" key={idx} title={field.checked ? "Mark your availability for this shift" : "This shift is not available"}>
                <div className={classes.join(" ")} onClick={lockSelections || (!allowToggle) ? null : () => checkHandler(field)}>{field.label}</div>
            </Col>
        );
    };

    const setShiftTitle = (e, shift) => {
        shift.title = e.target.value;
    };

    //console.log("Shifts at re-render", shifts);
    const shiftList = shifts.map((shift, idx) => {
        const id = (shift?.id ?? shift?.title)?.replace(/\W/g, '_') ?? "shift_" + Math.floor(Math.random() * 10000);
        const vals = [];

        dayList.forEach(d => {
            vals.push({
                id: id + d,
                label: d,
                checked: shift[d]
            });
        });

        if(fields.shifts[id] == null)
            fields.shifts[id] = { title: shift.title, days: {}, id: id };

        const shiftCells = vals.map((val, i) => getDayCell(id, val, i));
        const updateShiftTitle = (id) => {
            try {
                const item = document.getElementById(id + "name");

                if(item != null) {
                    item.value = shift.title;
                }
            }
            catch(e) {
                console.error(e);
            }
        };
        const shiftRow =  <Row className="job-shift py-2 px-3" key={idx}>
                            <Col>
                                <Row>
                                    <Col className="avail-header" xs="12">
                                        { allowEdit 
                                            ? (
                                                <Row>
                                                    <Col xs="10" sm="11" md="11" lg="11">
                                                        <InputFloating type="text" id={id + "name"} onChange={(e) => setShiftTitle(e, shift)} 
                                                            placeholder="Shift Name" label="Shift Name" defaultValue={shift.title} />
                                                        <script>{setTimeout(() => {updateShiftTitle(id)}, 100)}</script>
                                                    </Col>
                                                    <Col xs="2" sm="1" md="1" lg="1" className="py-3">
                                                        <FontAwesomeIcon onClick={e => deleteHandler(shift)} className="job-shift-delete" icon={['fa', 'trash']} size="lg"/>     
                                                    </Col>
                                                </Row>
                                            ) 
                                            : (<Label>{shift.title}</Label>)
                                        }
                                    </Col>
                                </Row>
                                <Row>{ shiftCells }</Row>
                            </Col>                            
                        </Row>;
        return shiftRow;
    });

    const commentsExist = (shifts?.length ?? 0) > 0 && shifts[0].comments != null && shifts[0].comments != "";

    const comments = !commentsExist ? <></> :
    (
        <Row className="job-shift-comments py-2 px-3">
            <Col xs="12" className="p-y-2">
                <Label>Comments:</Label>
            </Col>
            <Col xs="12" className="p-y-2">
                <p style={{fontStyle: "italic"}}>{shifts[0].comments}</p>
            </Col>
        </Row>
    );

    return (<div className="job-shift-details">
        {shiftList}
        { comments }
    </div>);
};

export const getAvailability = (allShifts, shiftStatus) => {
    const available = [];
    const temp = {};

    allShifts.forEach(s => {
        const shift = {
            ...s,
            id: s.title,
            comments: shiftStatus.comments
        };

        temp[s.id] = shift;
    });

    for(let id in shiftStatus.days) {
        const s = shiftStatus.days[id];

        if(temp[s.id] != null) {
            temp[s.id][s.label] = s.checked;
        }
        else {
            console.warn("The following was not written", s, temp);
        }
    };

    for(let sh in temp) {
        available.push(temp[sh]);
    }

    console.log("Availability Complete", available);
    return available;
};

export const displayShiftCheckboxes_old = (shifts, fields, onUpdate, disableAll = false) => {
    if(fields.days == null)
        fields.days = {};
    if(fields.shifts == null)
        fields.shifts = {};

    const shiftList = shifts.map((shift, index) => {
        //console.log("Shift", shift);
        const id = shift?.title?.replace(/\W/g, '_') ?? "shift_" + Math.floor(Math.random() * 10000);
        const vals = [
            {"id": id + "sun", "checked": shift.sun},
            {"id": id + "mon", "checked": shift.mon},
            {"id": id + "tue", "checked": shift.tue},
            {"id": id + "wed", "checked": shift.wed},
            {"id": id + "thu", "checked": shift.thu},
            {"id": id + "fri", "checked": shift.fri},
            {"id": id + "sat", "checked": shift.sat},
        ];

        if(fields.shifts[id] == null)
            fields.shifts[id] = { "title": shift.title, days: {} };

        const checkHandler = (event) => {
            var val = event.target;
            fields.days[val.id].checked = event.target.checked;
            if(onUpdate != null && typeof onUpdate == "function")
                onUpdate(fields);
            //console.log("Val", val, val.id);
        };

        const shiftCells = vals.map((val, idx) => {
            //console.log("Val", val);
            if(fields.days[val.id] == null)
                fields.days[val.id] = { id: id, checked: val.checked };
            return <Col className="avail-cell" key={idx} title={val.checked ? "Mark your availability for this shift" : "This shift is not available"}>
                    <Input type="checkbox" id={val.id} defaultChecked={val.checked} disabled={!val.checked || disableAll} onChange={checkHandler} />
                </Col>;
        });
        const shiftRow =  <Row className="job-shift py-2 px-3" key={index}>
                            <Col className="avail-header" xs="12" sm="3"><Label>{shift.title}</Label></Col>
                            { shiftCells }
                        </Row>;
        return shiftRow;
    });

    const commentsExist = (shifts?.length ?? 0) > 0 && shifts[0].comments != null && shifts[0].comments != "";

    const comments = !commentsExist ? <></> :
    (
        <Row className="job-shift-comments py-2 px-3">
            <Col xs="12" className="p-y-2">
                <Label>Comments:</Label>
            </Col>
            <Col xs="12" className="p-y-2">
                <p style={{fontStyle: "italic"}}>{shifts[0].comments}</p>
            </Col>
        </Row>
    );
    return <div className="job-shift-details">
        <Row className="job-shift-days py-2 px-3">
            <Col className="avail-header" xs="0" sm="4">&nbsp;</Col>
            <Col className="avail-cell"><Label>Sun</Label></Col>
            <Col className="avail-cell"><Label>Mon</Label></Col>
            <Col className="avail-cell"><Label>Tue</Label></Col>
            <Col className="avail-cell"><Label>Wed</Label></Col>
            <Col className="avail-cell"><Label>Thu</Label></Col>
            <Col className="avail-cell"><Label>Fri</Label></Col>
            <Col className="avail-cell"><Label>Sat</Label></Col>
        </Row>
        {shiftList}
        { comments }
    </div>;
};

export const getAddress = (address_1, address_2, city, state, postal_code, className) => {
    let cn = "address";
    if(className != null) {
        cn += " " + className;
    }
    return (
        <div className={cn}>            
            <div className="address-1">{address_1} {address_2 && ","} {address_2}</div>
            <div className="address-city-state">{city}, {state} {postal_code}</div>
        </div>
    )
};

export const getNotAuthorizedCell = (message) => {
    return (
        <Row className="warning-cell">
            <Col xs="12">{message}</Col>
        </Row>
    );
};