import React from 'react'
import { useHistory } from "react-router-dom"
import { Col, Row } from 'reactstrap'
import { isEmpty } from '../../shared/utility'
import BsIcon from './BsIcon';

const BackLink = (props) => {
    const history = useHistory();   
    const clickHandler = () => {
        if(!isEmpty(props.url)) {
            history.push(props.url);
        }
        else {
            history.goBack();
        }
    };
    let label = props.label ?? isEmpty(props.displayName) ? "Back" : "Back to " + props.displayName;
    // let title = props.title ?? "Click to return to the previous page";
    if(!props.override) {
        BackLinkProps.setBackLink(props.url, props.displayName, props.title);
    }

    const body = (
        <Row className="back-link-row p-0">
            <Col xs="12">
                <div className="back-link-container" onClick={clickHandler} title="Click to return to the previous page">
                    <BsIcon icon="chevron-left" size="1.25" color="dark" className="back-link-icon"/>
                    &nbsp;
                    <span className="back-link-text">{label}</span>
                </div>
            </Col>
        </Row>
    );

    return body;
}

export class BackLinkProps {
    static url = null;
    static text = null;
    static title = null;
    static show = false;

    static getBackLink() {
        return <BackLink url={this.url} displayName={this.text} title={this.title} override={true} />;
    }    

    static setBackLink (url, text, title) {
        this.url = url;
        this.text = text;
        this.title = title;
        //this.show = true;
    };

    static hideBackLink () {
        this.show = false;
    };
}

export default BackLink
