import { combineReducers } from 'redux';

//*** We import all of the reducers ***//

// Employer Reducers

// User Reducers
import userReducer from './userReducer';
import employerReducer from './employerReducer';
// Worker Reducers

// Example Reducers
import loggedReducer from './isLogged';


const allReducers = combineReducers({
    // Now we put all the reducers here. It should be 'name: reducerName' OR we can use the ES6 syntax sugar and just say reducerName (shortHand)
    logged: loggedReducer,
    user: userReducer,
    employer: employerReducer
});

export default allReducers;
